.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 0.25em;
    a {
      color: white;
      text-decoration: none;
      &:visited {
        color: white;
      }
    }
  }

  nav {
    ol {
      li {
        display: inline-block;
        color: white;
        a {
          color: white;

          &:visited {
            color: white;
          }
        }
        margin-right: 1em;
      }
    }
  }
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}