.home {
  text-align: left;

  section {
    margin-left: 2em;
    margin-bottom: 2em;

    max-width: 66%;

    display: inline-block;

    h1 {
      font-size: 1em;
      font-weight: bold;

      @include media(">small") {
        font-size: 1em;
      }

      @include media(">medium") {
        font-size: 2em;
        font-weight: normal;
      }
    }

    p {
      margin-top: 0.5em;
    }
  }


}