.modeOptions fieldset {
  border: none;
}


.modeOptions {
  :not(.lt-ie9) {

    label {
      cursor: pointer;
    }

    /* HTML5 Boilerplate accessible hidden styles */
    [type="radio"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    [type="radio"] + span {
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }

    /* the checked style using the :checked pseudo class */
    [type="radio"]:checked + span {
      background-color: beige;
      border: 1px solid beige;
    }

    /* never forget focus styling */
    [type="radio"]:focus + span {
      border: 1px solid green;
    }
  }
}