@import '_media';

$breakpoints: (small: 600px, medium: 800px, large: 1000px);

h1 {
  font-size: 2em;

  @include media(">small") {
    font-size: 3em;
  }

  @include media(">medium") {
    font-size: 4em;
  }
}