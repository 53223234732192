.svg-container {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.svg-content {
  display: inline-block;
}
